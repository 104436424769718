<template>
  <div class="mortgage-calculator-container">
    <div class="mortgage-calculator">
      <h2>房贷计算器</h2>
      <div class="input-container">
        <div class="input-group">
          <label for="area">房屋面积 (平方米)</label>
          <input type="number" id="area" v-model="area" @input="calculateTotalPrice" />
        </div>
        <div class="input-group">
          <label for="unitPrice">单价 (元/平方米)</label>
          <input type="number" id="unitPrice" v-model="unitPrice" @input="calculateTotalPrice" />
        </div>
        <div class="input-group">
          <label for="loanTerm">贷款期限 (年)</label>
          <input type="number" id="loanTerm" v-model="loanTerm" />
        </div>
      </div>
      <div class="comparison-container">
        <div class="comparison-item" v-for="(item, index) in comparisonItems" :key="index">
          <div class="comparison-header">
            <h3>方案 {{ index + 1 }}</h3>
            <button @click="removeComparisonItem(index)" class="remove-btn" v-if="comparisonItems.length > 1">删除</button>
          </div>
          <div class="input-group">
            <label :for="'downPaymentRatio' + index">首付比例 (%)</label>
            <input type="number" :id="'downPaymentRatio' + index" v-model="item.downPaymentRatio" @input="calculateComparison(index)" />
          </div>
          <div class="input-group">
            <label :for="'commercialRatio' + index">商业贷款比例 (%)</label>
            <input type="number" :id="'commercialRatio' + index" v-model="item.commercialRatio" @input="updateCommercialAmount(index)" />
          </div>
          <div class="input-group">
            <label :for="'commercialAmount' + index">商业贷款金额 (万元)</label>
            <input type="number" :id="'commercialAmount' + index" v-model="item.commercialAmount" @input="updateCommercialRatio(index)" />
          </div>
          <div class="input-group">
            <label :for="'commercialRate' + index">商业贷款年利率 (%)</label>
            <input type="number" :id="'commercialRate' + index" v-model="item.commercialRate" step="0.01" @input="calculateComparison(index)" />
          </div>
          <div class="input-group">
            <label :for="'providentFundRate' + index">公积金贷款年利率 (%)</label>
            <input type="number" :id="'providentFundRate' + index" v-model="item.providentFundRate" step="0.01" @input="calculateComparison(index)" />
          </div>
          <div class="result-item" v-if="item.showResults">
            <span class="result-label">首付金额:</span>
            <span class="result-value">{{ item.downPayment.toFixed(2) }} 万元</span>
          </div>
          <div class="result-item" v-if="item.showResults">
            <span class="result-label">贷款金额:</span>
            <span class="result-value">{{ item.loanAmount.toFixed(2) }} 万元</span>
          </div>
          <div class="result-item" v-if="item.showResults">
            <span class="result-label">月供:</span>
            <span class="result-value">{{ item.monthlyPayment.toFixed(2) }} 元</span>
          </div>
          <div class="result-item" v-if="item.showResults">
            <span class="result-label">总还款额:</span>
            <span class="result-value">{{ item.totalPayment.toFixed(2) }} 元</span>
          </div>
          <div class="result-item" v-if="item.showResults">
            <span class="result-label">总利息:</span>
            <span class="result-value">{{ item.totalInterest.toFixed(2) }} 元</span>
          </div>
        </div>
      </div>
      <button @click="addComparisonItem" class="add-btn">添加对比方案</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      area: 0,
      unitPrice: 0,
      loanTerm: 30,
      totalPrice: 0,
      comparisonItems: [
        {
          downPaymentRatio: 30,
          commercialRatio: 50,
          commercialAmount: 0,
          commercialRate: 4.85,
          providentFundRate: 3.25,
          downPayment: 0,
          loanAmount: 0,
          monthlyPayment: 0,
          totalPayment: 0,
          totalInterest: 0,
          showResults: false
        }
      ]
    }
  },
  methods: {
    calculateTotalPrice() {
      this.totalPrice = (this.area * this.unitPrice) / 10000;
      this.comparisonItems.forEach((item, index) => {
        this.calculateComparison(index);
      });
    },
    calculateComparison(index) {
      const item = this.comparisonItems[index];
      item.downPayment = this.totalPrice * (item.downPaymentRatio / 100);
      item.loanAmount = this.totalPrice - item.downPayment;
      
      const commercialLoanAmount = item.loanAmount * (item.commercialRatio / 100);
      const providentFundLoanAmount = item.loanAmount - commercialLoanAmount;
      
      const commercialMonthlyRate = item.commercialRate / 100 / 12;
      const providentFundMonthlyRate = item.providentFundRate / 100 / 12;
      const totalMonths = this.loanTerm * 12;
      
      const commercialMonthlyPayment = this.calculateMonthlyPayment(commercialLoanAmount, commercialMonthlyRate, totalMonths);
      const providentFundMonthlyPayment = this.calculateMonthlyPayment(providentFundLoanAmount, providentFundMonthlyRate, totalMonths);
      
      item.monthlyPayment = commercialMonthlyPayment + providentFundMonthlyPayment;
      item.totalPayment = item.monthlyPayment * totalMonths;
      item.totalInterest = item.totalPayment - item.loanAmount * 10000;
      
      item.commercialAmount = commercialLoanAmount;
      item.showResults = true;
    },
    calculateMonthlyPayment(loanAmount, monthlyRate, totalMonths) {
      return (loanAmount * 10000 * monthlyRate * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);
    },
    updateCommercialAmount(index) {
      const item = this.comparisonItems[index];
      item.commercialAmount = item.loanAmount * (item.commercialRatio / 100);
      this.calculateComparison(index);
    },
    updateCommercialRatio(index) {
      const item = this.comparisonItems[index];
      item.commercialRatio = (item.commercialAmount / item.loanAmount) * 100;
      this.calculateComparison(index);
    },
    addComparisonItem() {
      this.comparisonItems.push({
        downPaymentRatio: 30,
        commercialRatio: 50,
        commercialAmount: 0,
        commercialRate: 4.85,
        providentFundRate: 3.25,
        downPayment: 0,
        loanAmount: 0,
        monthlyPayment: 0,
        totalPayment: 0,
        totalInterest: 0,
        showResults: false
      });
      this.calculateTotalPrice();
    },
    removeComparisonItem(index) {
      this.comparisonItems.splice(index, 1);
      this.calculateTotalPrice();
    }
  }
}
</script>

<style scoped>

.mortgage-calculator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.mortgage-calculator {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
}

h2 {
  color: #4a5568;
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
}

.input-container {
  display: grid;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  color: #4a5568;
  margin-bottom: 5px;
  font-weight: 600;
}

input {
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #667eea;
}

input[readonly] {
  background-color: #f7fafc;
}

.calculate-btn {
  width: 100%;
  padding: 12px;
  background-color: #667eea;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.calculate-btn:hover {
  background-color: #5a67d8;
}

.results {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #e2e8f0;
}

.result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.result-label {
  font-weight: 600;
  color: #4a5568;
}

.result-value {
  font-weight: 700;
  color: #667eea;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.comparison-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 30px;
}

.comparison-item {
  background-color: #f7fafc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.comparison-item h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #4a5568;
}

.add-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.add-btn:hover {
  background-color: #45a049;
}

.comparison-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.remove-btn {
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-btn:hover {
  background-color: #c53030;
}

/* 添加媒体查询，针对宽屏设备 */
@media (min-width: 768px) {
  .mortgage-calculator {
    max-width: 90%;
  }

  .comparison-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/* 添加媒体查询，针对更宽的屏幕 */
@media (min-width: 1200px) {
  .mortgage-calculator {
    max-width: 1200px;
  }

  .comparison-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* 其他样式保持不变 */
</style>